<template>
  <div class="details">
    <div id="control-header">
      <h3 class="header">
        {{ $t("tableNames.details") }}
      </h3>
      <button id="newDetail" @click="addNew" v-if="changeable" class="btn waves-effect waves-light">
        <i class="fa fa-plus" style="font-size: 15px"></i>
        {{ $t("newElementFor.details") }}
      </button>
    </div>
    <table>
      <thead>
        <tr>
          <th class="l1Th">{{ $t("details.l1") }}</th>
          <th class="l2Th">{{ $t("details.l2") }}</th>
          <th class="widthTh">{{ $t("details.width") }}</th>
          <th class="updatedTh">{{ $t("details.updated") }}</th>
          <th class="actionTh" v-if="changeable">{{ $t("details.actions") }}</th>
        </tr>
      </thead>
      <tbody>
      <SoloDetail
          v-for="detail in details.filter(d => !(d.deleted))" :key="detail.id"
          v-bind:detail="detail"
          v-bind:editable="editable"
          v-bind:changeable="changeable"
          @startEditing="startEditing"
          @finishEditing="finishEditing"
      />
      </tbody>
    </table>
  </div>
</template>

<script>

import moment from 'moment';
import SoloDetail from "./SoloDetail";

export default {
  props: {
    itemId: {
      type: Number,
      required: true
    },
    details: {
      type: Array,
      required: true
    },
    changeable: {
      type: Boolean,
      required: true
    }
  },
  components: {
    SoloDetail
  },
  data() {
    return {
       editDetail: false,
      editable: {
        id: "",
        flag: true
      },
    }
  },
  methods: {
    moment,

    startEditing(id) {
      this.editable.id = id;
      this.editable.flag = false;
    },

    finishEditing(detail) {
      if (detail) {
        if (detail.deleted) {
           if (detail.id === "new") {
            // cancel new detail
            this.details.shift();
            this.update();
          } else if (detail.id < 0) {
            // if detected a deleted "new" (not yet saved) item, then just remove it from the details list:
            const idx = this.details.findIndex((el) => el.id === detail.id);
            if (idx >= 0) {
              this.details.splice(idx, 1);
            }
            this.update();
          } else {
             // delete an existed detail: do nothing. it was already marked as 'deleted' and will be deleted
             // from the server on saveItem
            this.update(detail);
          }
        } else {
          if (detail.id === "new") {
            // "save" a new detail: give it a temporary (negative) id (-1, -2, ...):
            let newId = this.details.filter((i) => i.id !== "new").reduce((a, i) => i.id < a ? i.id : a, 0);
            newId = (newId < 0) ? newId - 1 : -1;
            detail.id = newId;
          }
          this.update(detail);
        }
      } else {
        this.update();
      }
      this.editable.id = undefined;
      this.editable.flag = true;
    },

    addNew() {
      if (this.editable.flag === true) {
        let newDetail = {
          id: "new",
          itemId: this.itemId
        }
        if (!this.details) {
          this.details = []
        }
        this.details.unshift(newDetail);
        this.editable.id = "new";
      }
    },

    update(detail) {
      if (detail) {
        let det = this.details.find((el) => el.id === detail.id);
        if (det) {
          det.id = detail.id;
          det.width = detail.width;
          det.horizontalStep = detail.horizontalStep;
          det.verticalStep = detail.verticalStep;
        }
      }
      this.$emit("updateDetails", this.details);
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../public/styles/detailsTable.scss';
@import '../../../public/materialize-src/sass/materialize.scss';

h3 {
  text-align: center;
  font-size: 30px;
}

.plus {
  font-size: 25px;
}

.header {
  float: left;
  font-size: 22px;
  font-weight: bold;
}

#control-header {
  display: flex;
  justify-content: space-between;
}

</style>