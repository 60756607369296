<template>
  <tr>
    <td class="l1Th">
      <div v-show="!editDetail">{{ ui.methods.fmt(detail.horizontalStep) }}</div>
      <input id="l1Input" v-show="editDetail" v-model.number="horizontalStep">
    </td>
    <td class="l2Th">
      <div v-show="!editDetail">{{ ui.methods.fmt(detail.verticalStep) }}</div>
      <input id="l2Input" v-show="editDetail" v-model.number="verticalStep">
    </td>
    <td class="widthTh">
      <div v-show="!editDetail">{{ ui.methods.fmt(detail.width) }}</div>
      <input id="widthInput" v-show="editDetail" v-model.number="width">
    </td>
    <td class="updatedTh">{{ moment(detail.updated).format('DD.MM.YYYY HH:mm') }}</td>
    <td class="actionTh" v-if="changeable">
      <div class="fa fa-pencil actions" v-if="!editDetail" @click="edit">
        <span class="pencilhint">{{ $t("details.edit") }}</span>
      </div>
      <div class="fa fa-check actions" v-if="editDetail" @click="save">
        <span class="checkhint">{{ $t("details.save") }}</span>
      </div>
      <div class="fa fa-trash-o actions" v-if="!this.editDetail" @click="deleteDetail">
        <span class="deletehint"> {{ $t("details.delete") }}</span>
      </div>
      <div class="fa fa-window-close actions" v-if="this.editDetail" @click="cancel">
        <span class="closehint">{{ $t("details.cancel") }}</span>
      </div>
    </td>
  </tr>
</template>

<script>

import moment from 'moment';
import ui from "../utils/ui.vue";

export default {
  computed: {
    ui() {
      return ui
    }
  },
  props: {
    detail: {
      type: Object,
      required: true
    },
    editable: {
      type: Object,
      required: true
    },
    changeable: {
      type: Boolean,
      required: true
    }
  },

  watch: {
    changeable: function(newVal, oldVal) {
       if (!newVal && oldVal && this.editDetail) {
          this.cancel()
       }
    }
  },

  data() {
    return {
      editDetail: false,
      width: 0,
      horizontalStep: 0,
      verticalStep: 0
    }
  },

  mounted() {
    if (this.detail.id === "new") {
      this.edit()
    }
  },

  methods: {
    moment,
    edit() {
      if (this.editable.flag === true) {
        this.$emit('startEditing', this.detail.id);
        this.doEditable();
      }
    },

    doEditable() {
      if (this.editable.id === this.detail.id) {
        this.initFields();
        this.editDetail = true;
      }
    },

    deleteDetail() {
      if (this.editable.flag === true) {
        this.detail.deleted = true;
        this.$emit('finishEditing', this.detail);
        this.editDetail = false;
      }
    },

    initFields() {
      this.width = ui.methods.fmt(this.detail.width);
      this.horizontalStep = ui.methods.fmt(this.detail.horizontalStep);
      this.verticalStep = ui.methods.fmt(this.detail.verticalStep);
    },

    save() {
      if (
          ui.methods.isEmptyNumber(this.width)
          && ui.methods.isEmptyNumber(this.horizontalStep)
          && ui.methods.isEmptyNumber(this.verticalStep)
      ) {
        this.cancel();
      } else {
        this.detail.width = ui.methods.fmt(this.width);
        this.detail.horizontalStep = ui.methods.fmt(this.horizontalStep);
        this.detail.verticalStep = ui.methods.fmt(this.verticalStep);
        this.detail.updated = moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ")

        this.$emit('finishEditing', this.detail);
        this.editDetail = false;
      }
    },

    cancel() {
      if (this.detail.id === "new") {
        this.detail.deleted = true;
        this.$emit('finishEditing', this.detail);
      } else {
        //this.initFields();
        this.$emit('finishEditing');
      }
      this.editDetail = false;
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../public/styles/detailsTable.scss';
@import '../../../public/styles/hints';
@import '../../../public/materialize-src/sass/materialize.scss';

.deletehint, .restorehint, .pencilhint {
  bottom: 120%;
  left: -90%;
}

.checkhint, .closehint {
  bottom: 120%;
  left: -65%;
}

.actions {
  margin: 10px;
}
</style>