<template>
  <div id="app">
    <div class="container">
      <div class="title">
        <div class="item-name">
          <div id="back" class="fa fa-angle-left" @click="goBack()"></div>
          <span class="name">{{ $t("singleItem.item", [id]) }} </span>
          <span class="is-deleted" v-if="item.status === 'DELETED'">{{ $t("singleItem.deleted") }}</span>
        </div>
        <div class="item-tools">
          <div class="fa fa-clone" v-if="isRoleAdmin" @click="cloneItem">
            <span class="clonehint">{{ $t("singleItem.cloneHint") }}</span>
          </div>
          <div class="fa fa-trash-o" v-if="isRoleAdmin && item.status !== 'DELETED'" @click="deleteItem()">
            <span class="deletehint">{{ $t("singleItem.deleteHint") }}</span>
          </div>
          <div class="fa fa-arrow-circle-o-up" v-else-if="isRoleAdmin" @click="restoreItem">
            <span class="restorehint">{{ $t("singleItem.restoreHint") }}</span>
          </div>
          <span class="line">|</span>
          <span id="label" class="data">{{ $t("singleItem.lastUpdate") }}: </span>
          <span id="format" class="data">{{ moment(item.updated).format('DD.MM.YYYY') }}</span>
        </div>
      </div>

      <div class="content">
        <form autocomplete="off">
          <label for="system" class="active">
            <span v-if="!isSystemValid" class="invalid_value">{{ $t("singleItem.enterSystem") }}</span>
            <span v-else>{{ $t("singleItem.system") }}</span>
          </label>
          <input id="system" type="text" class="changeable" disabled v-model="item.system">

          <label class="active">{{ $t("singleItem.manufacturer") }}
            <span class="deleted" v-if="item.manufacturer.status === 'DELETED'" style="color:red">
              {{ $t("singleItem.deletedManufacturer") }}
            </span>
            <i class="fa fa-briefcase"
               v-if="context !== 'manufacturers'"
               @click="showManufacturer">
            </i>
          </label>

          <div>
            <select id="selectManufacturer" v-model="item.manufacturer" class="changeable" disabled>
              <option v-for="manufacturer in manufacturers"
                      :key="manufacturer.id"
                      v-bind:value="manufacturer">
                {{ manufacturer.name }}
              </option>
            </select>
          </div>

          <label for="mountType" class="active">{{ $t("singleItem.mountType") }}</label>
          <div>
            <input id="mountType" type="text" v-model="item.mountType" list="selectMountTypes" class="changeable" disabled>
            <datalist id="selectMountTypes" class="changeable" disabled>
              <option v-for="mountType in this.mountTypes" :key="mountType"
                      v-bind:value="mountType">
                {{ mountType }}
              </option>
            </datalist>
          </div>

          <label for="width" class="active">
            <span v-if="!isWidthValid" class="invalid_value">{{ $t("singleItem.enterWidth") }}</span>
            <span v-else>{{ $t("singleItem.width") }}</span>
          </label>
          <input id="width" type="text" v-model.number="item.width" class="changeable" disabled >

          <label for="depth" class="active">
            <span v-if="!isDepthValid" class="invalid_value">{{ $t("singleItem.enterDepth") }}</span>
            <span v-else class="active">{{ $t("singleItem.depth") }}</span>
          </label>
          <input id="depth" type="text" class="changeable" disabled v-model.number="item.depth">

          <label for="thickness" class="active">
            <span v-if="!isThicknessValid" class="invalid_value">{{ $t("singleItem.enterThickness") }}</span>
            <span v-else>{{ $t("singleItem.thickness") }}</span>
          </label>
          <input id="thickness" type="text" class="changeable" disabled v-model.number="item.thickness">

          <label for="l1" class="active">
            <span v-if="!isL1Valid" class="invalid_value">{{ $t("singleItem.enterHorizontalStep") }}</span>
            <span v-else>{{ $t("singleItem.horizontalStep") }}</span>
          </label>
          <input id="l1" type="text" class="changeable" disabled v-model.number="item.horizontalStep">

          <label for="l2" class="active">
            <span v-if="!isL2Valid" class="invalid_value">{{ $t("singleItem.enterVerticalStep") }}</span>
            <span v-else>{{ $t("singleItem.verticalStep") }}</span>
          </label>
          <input id="l2" type="text" class="changeable" disabled v-model.number="item.verticalStep">

          <label for="user" class="active">{{ $t("singleItem.user") }}</label>
          <input id="user" type="text" class="validate" disabled v-model="item.userName">

          <div id="controller" v-if="isRoleAdmin && item.status !== 'DELETED'">
            <button type="button" id="edit" class="btn waves-effect waves-light" @click="editItemFields()"  >
              {{ $t("singleItem.edit") }}<i class="material-icons right">edit</i>
            </button>

            <button type="button" id="submit-edit" class="btn waves-effect waves-light" @click="submitChanges" >
              {{ $t("singleItem.submit") }}<i class="material-icons right">done</i>
            </button>

            <button type="button" id="reset" class="btn waves-effect waves-light" @click="resetFields()">
              {{ $t("singleItem.reset") }}<i class="material-icons right">cancel</i>
            </button>
          </div>
        </form>
      </div>

      <vue-confirm-dialog></vue-confirm-dialog>

      <div class="image">
        <div class="image spin" v-if="upload">
          <RingLoader color="red"/>
        </div>
        <div v-else>
          <button class="download-photo-button btn waves-effect waves-light" v-if="item.imageUrl">
            <a :href="item.imageUrl" download>{{ $t("singleItem.downloadImage") }}</a>
          </button>

          <div v-if="isEmptyString(item.imageUrl)" @click="onSelectFile">
            <img src="../../../public/no-photo.jpg" v-bind:alt="$t('singleItem.noImageAlt')">
          </div>
          <div v-else>
            <img :src="item.imageUrl"
                 style="width:100%; margin-bottom: 5%; vertical-align:baseline"
                 v-bind:alt="$t('singleItem.imageAlt')">

            <div v-if="changeMode">
              <div id="delete-img" @click="onDeleteImage">
                <div id="deleteImg" class="fa fa-trash-o">
                  <span> {{ $t("singleItem.deleteImage") }}</span>
                </div>
              </div>
            </div>
          </div>

          <div v-if="isSetImageFromTicket()">
            <div id="ticket-img" @click="setImageFromTicket">
              <div id="ticketImg" class="fa fa-ticket">
                <span> {{ $t("singleItem.setImageFromTicket") }}</span>
              </div>
            </div>
          </div>

          <div v-if="isReturnToOriginalImage()">
            <div id="orig-img" @click="returnOriginalImage">
              <div id="origImg" class="fa fa-arrow-circle-o-up">
                <span> {{ $t("singleItem.returnOriginalImage") }}</span>
              </div>
            </div>
          </div>

          <div v-if="changeMode && isEmptyString(item.imageUrl)">
            <input type="file" accept="image/*" @change="onFileSelected" style="display: none" ref="fileInput">
            <div id="upload-img" @click="onSelectFile">
              <div id="fa-upload" class="fa fa-upload" >
                <span> {{ $t("singleItem.uploadImage") }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="logs">
        <LogMenu v-bind:historyLogs="historyLogs" v-if="isRoleAdmin"/>
        <Details v-bind:itemId="item.id"
                 v-bind:details="item.details"
                 v-bind:changeable="changeMode"
                 @updateDetails="update"
                 :key="detailsKey"
        />
      </div>
    </div>
  </div>
</template>


<script>
import $ from 'jquery'
import ManufacturersBoard from "../manufacturer/ManufacturersBoard";
import axios from "axios";
import moment from 'moment';
import VueToast from "vue-toast-notification";
import 'vue-toast-notification/dist/theme-default.css';
import Link from "../utils/Link";
import ui from "../utils/ui";
import VueConfirmDialog from "vue-confirm-dialog";
import Vue from "vue";
import {RingLoader} from '@saeris/vue-spinners'
import LogMenu from "../historyLog/LogMenu";
import Details from "./Details";
import App from "../../App";
import SideBarMenu from "@/components/SideBarMenu.vue";

Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

export default {
  props: {
    id: {
      type: String,
      default: null,
    },
  },

  components: {
    RingLoader,
    LogMenu,
    Details
  },

  data() {
    return {
      item: {
        id: -1,
        details: [],
        manufacturer: {}
      },
      manufacturers: [],
      mountTypes: [],
      selectedFile: null,
      isSystemValid: true,
      isWidthValid: true,
      isDepthValid: true,
      isThicknessValid: true,
      isL1Valid: true,
      isL2Valid: true,
      upload: true,
      historyLogs: [],
      changeMode: false,
      detailsKey: 0,
      ticketData: undefined,
      originalImageUrl: undefined,
      isRoleAdmin: false,
      context: undefined
    };
  },

  mounted() {
    this.context = window.localStorage.getItem("headerTitle");
    this.isRoleAdmin = SideBarMenu.methods.isRoleAdmin();
    this.upload = true;
    this.initialization().then(() => {
      this.upload = false;
    }).catch((e) => {
      console.error(e);
      this.upload = false;
    });
  },

  methods: {
    update(details) {
      if (details) {
        this.item.details = details
      }
      this.detailsKey++;  // used to refresh Details
    },

    getLogs() {
      const headers = Link.methods.getHeaders();
      return axios.get(
        Link.methods.getItemLogs(this.id),
        {headers}
      ).then(res => {
        this.historyLogs = res.data;

        for (let i = 0; i < this.historyLogs.length; i++) {
          this.historyLogs[i].message = App.methods.getLink(this.historyLogs[i].message);
        }
      }).catch(reason => {
        console.error("SingleItem.getLogs error:", reason);
      })
    },

    onFileSelected(event) {
      this.selectedFile = event.target.files[0];
      const fd = new FormData();
      fd.append('file', this.selectedFile)

      const headers = {
        "Authorization": Link.methods.getToken(),
      }

      if (this.selectedFile != null) {
        this.upload = true;
        axios.post(
          Link.methods.getFilePhotoUploadUrl(),
          fd,
          {headers}
        ).then(res => {
          this.item.imageUrl = res.data;
          this.upload = false;
        }).catch(() => {
          this.createToast(this.$t("singleItem.fileNotUploaded"), "error")
          this.upload = false;
        })
      }
    },

    goBack(n) {
      window.localStorage.removeItem("ticketData");

      if (n == null) {
        n = -1;
      }

      return this.$router.go(n);
    },

    updateFieldsFromTicket() {
      let hasChanges = false;
      if (this.context === 'tickets') {
        const savedData = window.localStorage.getItem("ticketData");
        if (!savedData) {
          return;
        }
        this.ticketData = JSON.parse(savedData);
      }

      if (this.ticketData == null) {
        return;
      }

      if (this.ticketData.itemId !== this.id) {
        return
      }

      if (
          this.isEmptyString(this.item.mountType)
          && !this.isEmptyString(this.ticketData.mountType)
      ) {
        hasChanges = true;
        this.item.mountType = this.ticketData.mountType;
      }

      if (
          this.isEmptyNumber(this.item.thickness)
          && !this.isEmptyNumber(this.ticketData.thickness)
      ) {
        hasChanges = true;
        this.item.thickness = this.ticketData.thickness;
      }

      if (
          this.isEmptyNumber(this.item.depth)
          && !this.isEmptyNumber(this.ticketData.depth)
      ) {
        hasChanges = true;
        this.item.depth = this.ticketData.depth;
      }

      if (!this.isEmptyString(this.ticketData.imageUrl)) {
        if (this.isEmptyString(this.item.imageUrl)) {
          hasChanges = true;
          this.item.imageUrl = this.ticketData.imageUrl;
        } else if (this.ticketData.imageUrl !== this.item.imageUrl) {
          hasChanges = true;
        }
      }

      const detail = {
        "width": ui.methods.fmt(this.ticketData.width),
        "verticalStep": ui.methods.fmt(this.ticketData.verticalStep),
        "horizontalStep": ui.methods.fmt(this.ticketData.horizontalStep)
      };

      this.ticketData.isDetailNew = (
          !this.isEmptyNumber(detail.width)
          || !this.isEmptyNumber(detail.verticalStep)
          || !this.isEmptyNumber(detail.horizontalStep)
      );

      if (this.ticketData.isDetailNew === true) {
        if (
            this.item.width === detail.width
            && this.item.verticalStep === detail.verticalStep
            && this.item.horizontalStep === detail.horizontalStep
        ) {
          this.ticketData.isDetailNew = false;
        } else {
          for (let det of this.item.details) {
            if (
                det.width === detail.width
                && det.verticalStep === detail.verticalStep
                && det.horizontalStep === detail.horizontalStep
            ) {
              this.ticketData.isDetailNew = false;
              break;
            }
          }
        }
      }

      if (this.ticketData.isDetailNew === true) {
        let newDetail = {
          id: "new",
          itemId: this.id,
          width: detail.width,
          verticalStep: detail.verticalStep,
          horizontalStep: detail.horizontalStep
        }
        this.item.details.unshift(newDetail);
      }

      if (
          hasChanges
          || this.ticketData.isDetailNew === true
      ) {
        this.editItemFields();
      } else {
        this.ticketData = undefined;
      }
    },

    initialization() {
      return this.getMountTypes().then(mountTypes => {
        this.mountTypes = mountTypes;

        return ManufacturersBoard.methods.getManufacturers().then(m => {
          this.manufacturers = m

          return this.getSingleItem(this.id).then(d => {
            if (d == null) {
              return this.$router.replace({name: 'newItem'});
            }

            this.item = d.data;
            this.formatNumericFields();
            this.checkFields();

            this.originalImageUrl = (!this.isEmptyString(this.item.imageUrl)) ? this.item.imageUrl : undefined;

            for (let detail of this.item.details) {
              detail.width = ui.methods.fmt(detail.width);
              detail.verticalStep = ui.methods.fmt(detail.verticalStep);
              detail.horizontalStep = ui.methods.fmt(detail.horizontalStep);
            }

            const manufacturerId = this.item.manufacturer.id;
            if (
                !this.isEmptyNumber(manufacturerId)
                && !this.manufacturers.find((it) => it.id === this.item.manufacturer.id)
            ) {
               // maybe manufacturer was deleted
              let headers = Link.methods.getHeaders();
              return axios.get(
                  Link.methods.getManufacturerById(manufacturerId),
                  {headers}
              ).then((res) => {
                const manufacture = res.data;
                this.manufacturers.push(manufacture);
                this.updateFieldsFromTicket();
              })
            }

            this.updateFieldsFromTicket();
          }).catch((e) => {
             console.error(e);
          });
        });
      }).then(() => {
        return this.getLogs();
      })
    },

    resetFields() {
      this.$data.changeMode = false;
      $('.changeable').prop('disabled', true);
      $('#edit').css('display', 'block');
      $('#reset').css('display', 'none');
      $('#submit-edit').css('display', 'none');

      if (this.$data.ticketData != null) {
         this.goBack();
      } else {
        this.initialization();
      }
    },

    moment,

    restoreItem() {
      this.$confirm(
        {
          message: this.$t("singleItem.restoreMessage", [this.item.system]),
          button: {
            no: this.$t("singleItem.confirmNo"),
            yes: this.$t("singleItem.confirmYes")
          },

          callback: confirm => {
            if (confirm) {
              let headers = Link.methods.getHeaders();
              axios.delete(
                Link.methods.getItemRestoreUrl(this.id),
                {headers}
              ).then(() => {
                this.item.status = 'ACTIVE';
              }).catch(() => {
                this.createToast(this.$t("singleItem.itemWasNotRestored"), "error")
              })
            }
          }
        }
      )
    },

    deleteItem() {
      this.$confirm(
        {
          message: this.$t("singleItem.deleteMessage", [this.item.system]),
          button: {
            no: this.$t("singleItem.confirmNo"),
            yes: this.$t("singleItem.confirmYes")
          },
          callback: confirm => {
            if (confirm) {
              let headers = Link.methods.getHeaders();
              axios.delete(
                  Link.methods.getItemDeleteUrl(this.id),
                  {headers}
              ).then( () => {
                this.createToast( this.$t('singleItem.itemDelete', [this.item.system]), "success");
                this.goBack()
              }).catch( () => {
                this.createToast( this.$t('singleItem.itemDeleteError', [this.item.system]), "error");
              });
            }
          }
        }
      )
    },

    isEmptyString(value) {
      return ui.methods.isEmptyString(value);
    },

    isEmptyNumber(value) {
      return ui.methods.isEmptyNumber(value);
    },

    cloneItem() {
      const isValid = this.checkFields();
      if (!isValid) {
        return
      }

      let headers = Link.methods.getHeaders();
      axios.post(
        Link.methods.getItemsCreateUrl(),
        this.item,
        {headers}
      ).then(response => {
        this.createToast(this.$t("singleItem.toastClone", [this.item.system]), "success");
        this.item.id = response.data.id;
        this.item.status = response.data.status;
        this.item.details = response.data.details;
        this.$router.replace({name: 'itemById', params: {"id": this.item.id.toString()}});
        setTimeout(() => {
          this.getLogs();
          this.editItemFields();
        })
      }).catch((e) => {
        console.error(e);
        this.createToast(this.$t("singleItem.itemNotCloned"), "error")
      });
    },

    getSingleItem(id) {
      const headers = Link.methods.getHeaders();
      return axios.get(Link.methods.getItemById(id), {headers}).catch(() => {
        this.createToast(this.$t("singleItem.itemNotFound", [id]), "error")
      });
    },

    sendItemEditRequest(id) {
      this.item.updated = moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ")
      const headers = Link.methods.getHeaders();
      return axios.put(Link.methods.getItemUpdate(id), this.item, {headers}).then(() => {
        this.createToast(this.$t("singleItem.toastEdited", [this.item.system]), "success");
        this.getLogs();

        if (this.ticketData != null) {
          return axios.put(
            Link.methods.getTicketStatusChangeUrl(this.ticketData.ticketId, 'CLOSED'),
            {},
            {headers}
          ).then(() => {
            this.goBack(-2);
          }).catch((e) => {
            console.error(e);
            return this.createToast(this.$t("singleItem.unableToCloseTicket"), "error");
          })
        }
      }).catch((e) => {
        console.error(e);
        return this.createToast(this.$t("singleItem.itemNotEdited"), "error");
      });
    },

    editItemFields() {
      this.$data.changeMode = true;
      $('.changeable').prop('disabled', false);
      $('#edit').css('display', 'none');
      $('#reset').css('display', 'block')
      $('#submit-edit').css('display', 'block');
      $('.image').css('pointer-events', 'auto')
    },

    editRequest() {
      this.sendItemEditRequest(this.id);

      this.$data.changeMode = false;
      $('.changeable').prop('disabled', true);
      $('#edit').css('display', 'block');
      $('#reset').css('display', 'none');
      $('#submit-edit').css('display', 'none');
    },

    createToast(msg, type) {
      Vue.use(VueToast);
      Vue.$toast.open({
        type: type,
        message: msg,
        position: "bottom-right",
        duration: 5000
      });
    },

    formatNumericFields() {
      this.item.witdh = ui.methods.fmt(this.item.width);
      this.item.depth = ui.methods.fmt(this.item.depth);
      this.item.thickness = ui.methods.fmt(this.item.thickness);
      this.item.horizontalStep = ui.methods.fmt(this.item.horizontalStep);
      this.item.verticalStep = ui.methods.fmt(this.item.verticalStep);
    },

    checkFields() {
      this.isSystemValid = !!this.item.system;
      this.isWidthValid = !this.isEmptyNumber(this.item.width);
      this.isDepthValid = !this.isEmptyNumber(this.item.depth) || this.item.depth == null || !isNaN(this.item.depth);
      this.isThicknessValid = !this.isEmptyNumber(this.item.thickness) || this.item.thickness == null || !isNaN(this.item.thickness);
      this.isL1Valid = !this.isEmptyNumber(this.item.horizontalStep);
      this.isL2Valid = !this.isEmptyNumber(this.item.verticalStep);
      return this.isSystemValid
          && this.isWidthValid
          && this.isL2Valid
          && this.isL1Valid
          && this.isDepthValid
          && this.isThicknessValid;
    },

    submitChanges() {
      if (!this.checkFields()) {
        return;
      }
      this.formatNumericFields();

      $('.image').css('pointer-events', 'none')

      if (this.ticketData != null && this.ticketData.isDetailNew && this.detailsKey === 0) {
        if (this.item.details.length > 0 && this.item.details[0].id === 'new') {
          this.item.details[0].id = -1;
        }
      }
      this.editRequest();
    },

    getMountTypes() {
      const headers = Link.methods.getHeaders();
      return axios.get(
        Link.methods.getMountTypes(),
        {headers}
      ).then(res => {
        return res.data;
      })
    },

    onDeleteImage() {
      if (this.item.imageUrl != null) {
        this.item.imageUrl = null;
      }
    },

    onSelectFile() {
      this.$refs.fileInput.value = null;
      this.$refs.fileInput.click();
    },

    isSetImageFromTicket() {
      if (!this.upload && this.changeMode && this.ticketData != null) {
        return (
            !this.isEmptyString(this.ticketData.imageUrl)
            && this.item.imageUrl !== this.ticketData.imageUrl
        );
      }

      return false;
    },

    isReturnToOriginalImage() {
      if (!this.upload && this.changeMode) {
        return (
            !this.isEmptyString(this.originalImageUrl)
            && this.item.imageUrl !== this.originalImageUrl
        );
      }

      return false;
    },

    setImageFromTicket() {
       if (
         this.ticketData != null
         && !this.isEmptyString(this.ticketData.imageUrl)
       ) {
         this.item.imageUrl = this.ticketData.imageUrl;
       }
    },

    returnOriginalImage() {
      if (!this.isEmptyString(this.originalImageUrl)) {
        this.item.imageUrl = this.originalImageUrl;
      }
    },

    showManufacturer() {
      this.$router.push({name: 'manufacturerById', params: {"id": this.item.manufacturer.id.toString()}});
    }
  }
}
</script>


<style scoped lang="scss">
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import '../../../public/materialize-src/sass/materialize.scss';
@import '../../../public/styles/singleItem';
@import '../../../public/styles/hints.scss';

#ticket-img, #orig-img, #delete-img, #upload-img {
  display: flex;
}

#upload-img:hover, #delete-img:hover, #ticket-img:hover, #orig-img:hover {
  #fa-upload {
    color: red;
  }
}

.invalid_value {
  color: red;
  font-size: 13px;
}

.spin {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-left: 0;
}

.is-deleted {
  color: red;
  font-weight: bold;
}

.btn {
  font-size: 13px;
  line-height: initial;
  padding: 0 4px 0 6px;
}

i.right {
  margin-left: 4px;
}

</style>
